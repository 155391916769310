<template>
  <el-container>
    <el-aside class="nsi-menu">
      <el-input
        id="search_input"
        placeholder="Поиск по наименованию"
        clearable
        class="mb-0"
        prefix-icon="el-icon-search"
        v-model="searchString">
      </el-input>
      <el-menu :default-active="activeLink" :router="true" style="height: calc(100% - 39px - 4px)" class="mt-1 mb-1">
        <el-divider content-position="left">Корпоративная НСИ</el-divider>
          <el-menu-item  v-for="item in searchByMenuItems.filter(x => x.group == 'corpNsi')" :key="item.path" :index="item.path">
            <span>{{item.name}}</span>
          </el-menu-item>
          <el-divider content-position="left">Локальная НСИ</el-divider>
          <el-menu-item  v-for="item in searchByMenuItems.filter(x => x.group == 'localNsi')" :key="item.path" :index="item.path">
            <span>{{item.name}}</span>
          </el-menu-item>
      </el-menu>
    </el-aside>
    <div class="nsi-content ml-2">
      <router-view />
    </div>
  </el-container>
</template>

<script>
export default {
  name: 'Nsi',
  data() {
    return {
      activeLink: null,
      searchString: '',
      menuItems: [
        // {
        //   name: 'Классы напряжения',
        //   path: '/nsi/base-voltages',
        //   group: 'corpNsi',
        // },
        // {
        //   name: 'Обмотки ТТ/ТН',
        //   path: '/nsi/transformer-windings',
        //   group: 'corpNsi',
        // },
        {
          name: 'Организации',
          path: '/nsi/organizations',
          group: 'corpNsi',
        },
        {
          name: 'Энергообъекты',
          path: '/nsi/energy-objects',
          group: 'corpNsi',
        },
        {
          name: 'Первичное оборудование',
          path: '/nsi/primary-equipments',
          group: 'corpNsi',
        },
        {
          name: 'ЛЭП',
          path: '/nsi/lines',
          group: 'corpNsi',
        },
        {
          name: 'Фирменные устройства РЗА',
          path: '/nsi/product-asset-models',
          group: 'corpNsi',
        },
        {
          name: 'Устройства РЗА',
          path: '/nsi/second-equipment-panels',
          group: 'corpNsi',
        },
        {
          name: 'Функции устройств РЗА',
          path: '/nsi/second-equipments',
          group: 'corpNsi',
        },
        {
          name: 'Стандартные функции РЗА',
          path: '/nsi/psr-type',
          group: 'corpNsi',
        },
        {
          name: 'Оценки',
          path: '/nsi/estimations',
          group: 'localNsi',
        },
        {
          name: 'Организационные причины',
          path: '/nsi/staff-culpabilities',
          group: 'localNsi',
        },
        {
          name: 'Технические причины',
          path: '/nsi/technical-reasons',
          group: 'localNsi'
        },
        {
          name: 'Места коротких замыканий',
          path: '/nsi/short-circuit-places',
          group: 'localNsi',
        },
        {
          name: 'Виды коротких замыканий',
          path: '/nsi/short-circuit-forms',
          group: 'localNsi',
        },
        {
          name: 'Детализированная причина неправильной работы',
          path: '/nsi/misoperation-reasons',
          group: 'localNsi',
        },
        {
          name: 'Поврежденные фазы',
          path: '/nsi/faulted-phases',
          group: 'localNsi',
        },
        {
          name: 'Категории персонала',
          path: '/nsi/staff-categories',
          group: 'localNsi',
        },
        {
          name: 'Комплексы РЗА',
          path: '/nsi/second-equipment-types',
          group: 'localNsi',
        },
        {
          name: 'Исполнения',
          path: '/nsi/executions',
          group: 'localNsi',
        },
        {
          name: 'Среды передачи информации',
          path: '/nsi/transmission-mediums',
          group: 'localNsi',
        },
        {
          name: 'Дополнительная информация',
          path: '/nsi/card-information',
          group: 'localNsi',
        },
      ]
    };
  },
  computed: {
    searchByMenuItems() {
      return this.menuItems.filter((item) => item.name.toLowerCase().indexOf(this.searchString.toLowerCase()) !== -1);
    }
  },
  mounted() {
    this.activeLink = this.$route.fullPath;
  },
  methods: {},
  watch: {
    $route(newVal) {
      this.activeLink = newVal.path;
    },
  },
};
</script>

<style>
  #search_input{
      border-radius: 0px;
      width: 99%;
      border-right: none;
      border-top: none;
      border-left: none;
      margin-right: .5rem;
  }
</style>

<style scoped>
  .el-divider--horizontal {
      width: 97%;
      margin: 24px 0 12px 0;
  }
  .el-divider__text.is-left {
      left: 15px;
      transform: translateY(-50%);
  }
  .el-divider__text {
      position: absolute;
      padding: 0 5px;
      font-weight: 500;
      color: #909399;
      font-size: 12px;
  }
</style>
